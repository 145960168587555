/** media breakpoints mixins */
.media-breakpoint-down(@breakpoint;

@Content) {
    @dimension: (@breakpoint - 1);

    @media screen and (max-width: @dimension) {
        @Content();
    }
}

.media-breakpoint-up(@breakpoint;

@Content) {
    @media screen and (min-width: @breakpoint) {
        @Content();
    }
}

.media-breakpoint-between(@breakpointLeft;
@breakpointRight;

@Content) {
    @dimensionRight: (@breakpointRight - 1);

    @media screen and (min-width: @breakpointLeft) and (max-width: @dimensionRight) {
        @Content();
    }
}
